/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/prismic-configuration');

// exports.onClientEntry = () => {
//   console.log("We've started!")
// }
//
// exports.onPreRouteUpdate = ({ location, prevLocation }) => {
// console.log("to", location.pathname)
// console.log("from", prevLocation ? prevLocation.pathname : null)
// }
//
// exports.onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname)
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
// }

// Prismic CMS configuration
registerLinkResolver(linkResolver);
