module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"bisflow","defaultLang":"en-us","pages":[{"type":"Features","match":"/features/:uid","path":"/features","component":"/app/src/templates/features.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BisFlow","short_name":"BisFlow","start_url":"/","background_color":"#6b75ff","theme_color":"#6b75ff","display":"minimal-ui","icon":"src/images/bisflow-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b89f82ee30b2955d6bb6051e36c648a1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
