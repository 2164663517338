export const linkResolver = doc => {
  // URL for features pages
  if (doc.type === 'features') return `/features/${doc.uid}`

  // URL for a page type
  if (doc.type === 'page') return `/${doc.uid}`

  // Backup for all other types
  return '/'
}
